@keyframes animate {
    0%{
        transform: translateY(0) rotate(0deg);
        opacity: 1;
        border-radius: 0;
    }
    100%{
        transform: translateY(-1000px) rotate(720deg);
        opacity: 0;
        border-radius: 50%;
    }
}

.background {
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    margin: 0;
    padding: 0;
    background: #74a7fe;
    overflow: scroll;
    z-index: -1;
}
.background li {
    position: absolute;
    display: block;
    list-style: none;
    width: 20px;
    height: 20px;
    background: rgba(255, 255, 255, 0.2);
    animation: animate 19s linear infinite;
}




.background li:nth-child(0) {
    left: 68%;
    width: 160px;
    height: 160px;
    bottom: -160px;
    animation-delay: 1s;
}
.background li:nth-child(1) {
    left: 79%;
    width: 183px;
    height: 183px;
    bottom: -183px;
    animation-delay: 2s;
}
.background li:nth-child(2) {
    left: 17%;
    width: 176px;
    height: 176px;
    bottom: -176px;
    animation-delay: 3s;
}
.background li:nth-child(3) {
    left: 6%;
    width: 108px;
    height: 108px;
    bottom: -108px;
    animation-delay: 12s;
}
.background li:nth-child(4) {
    left: 86%;
    width: 124px;
    height: 124px;
    bottom: -124px;
    animation-delay: 14s;
}
.background li:nth-child(5) {
    left: 67%;
    width: 147px;
    height: 147px;
    bottom: -147px;
    animation-delay: 3s;
}
.background li:nth-child(6) {
    left: 57%;
    width: 154px;
    height: 154px;
    bottom: -154px;
    animation-delay: 28s;
}
.background li:nth-child(7) {
    left: 7%;
    width: 106px;
    height: 106px;
    bottom: -106px;
    animation-delay: 31s;
}
.background li:nth-child(8) {
    left: 28%;
    width: 155px;
    height: 155px;
    bottom: -155px;
    animation-delay: 7s;
}
.background li:nth-child(9) {
    left: 32%;
    width: 186px;
    height: 186px;
    bottom: -186px;
    animation-delay: 2s;
}
.background li:nth-child(10) {
    left: 24%;
    width: 157px;
    height: 157px;
    bottom: -157px;
    animation-delay: 30s;
}
.background li:nth-child(11) {
    left: 52%;
    width: 124px;
    height: 124px;
    bottom: -124px;
    animation-delay: 27s;
}
.background li:nth-child(12) {
    left: 20%;
    width: 159px;
    height: 159px;
    bottom: -159px;
    animation-delay: 22s;
}
.background li:nth-child(13) {
    left: 11%;
    width: 167px;
    height: 167px;
    bottom: -167px;
    animation-delay: 57s;
}
.background li:nth-child(14) {
    left: 13%;
    width: 133px;
    height: 133px;
    bottom: -133px;
    animation-delay: 56s;
}
.background li:nth-child(15) {
    left: 80%;
    width: 185px;
    height: 185px;
    bottom: -185px;
    animation-delay: 57s;
}
.background li:nth-child(16) {
    left: 60%;
    width: 171px;
    height: 171px;
    bottom: -171px;
    animation-delay: 7s;
}
.background li:nth-child(17) {
    left: 25%;
    width: 197px;
    height: 197px;
    bottom: -197px;
    animation-delay: 46s;
}
.background li:nth-child(18) {
    left: 48%;
    width: 153px;
    height: 153px;
    bottom: -153px;
    animation-delay: 35s;
}
.background li:nth-child(19) {
    left: 34%;
    width: 120px;
    height: 120px;
    bottom: -120px;
    animation-delay: 50s;
}

.info {
    position: fixed;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    color: white;
    min-width: 220px;
    text-shadow: 2px 2px 10px rgb(0, 0, 0);
}
.infoBtn {
    /* background: transparent */
    color: white;
    margin: 0 0 0 3px;
    
}
.infoBtn:hover {
    scale: 1.1;
}

.viewBtn {
    position: fixed;
    bottom: 38px;
    left: 50%;
    transform: translateX(-50%)
}

